import PropTypes from 'prop-types';

import { contentfulMediaType } from 'types/common/contentfulMedia.types';

export const seoMetadataType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  canonicalUrl: PropTypes.string,
  image: contentfulMediaType,
  noIndex: PropTypes.bool,
});
