import PropTypes from 'prop-types';

import { contentfulLayoutType } from 'types/common/contentfulLayout.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { seoMetadataType } from 'types/common/seoMetadata.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const commonProps = {
  layout: contentfulLayoutType.isRequired,
  layoutType: PropTypes.oneOf(['primary', 'secondary']),
  notes: PropTypes.arrayOf(simpleRichTextType),
  disclosures: PropTypes.arrayOf(simpleRichTextType),
  seoMetadata: seoMetadataType.isRequired,
};

export const pageContainerProps = {
  ...commonProps,
  children: PropTypes.node.isRequired,
};

export const pageContainerType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  slug: PropTypes.string.isRequired,
});
