import PropTypes from 'prop-types';

import { contentfulFooterNavType, contentfulHeaderNavType } from 'types';
import { headerNavType } from 'humanity/sections/headerNav/headerNav.types';

export const contentfulLayoutType = PropTypes.shape({
  headerNavigation: PropTypes.oneOfType([contentfulHeaderNavType, headerNavType])
    .isRequired,
  footerNavigation: contentfulFooterNavType,
});
