import { headerNavStandard } from 'humanity/sections/headerNav';

export const pageContainerStandard = {
  sys: {
    id: '32AcuXIxz8Y434FWbPOxE2',
  },
  internalTitle: 'Page Container: Index (Homepage)',
  slug: 'index',
  seoMetadata: {
    sys: {
      id: 'VwyAbhxpBKOp2FaKvBzV0',
    },
    title: 'Affordable, Full-Service 401(k) Plans for SMBs',
    description:
      'Human Interest makes it easy and affordable to help your employees save for retirement.',
    noIndex: false,
    image: null,
    canonicalUrl: '/',
  },
  layout: {
    sys: {
      id: '5Dp0YOtO45t9vruD0JwAWO',
    },
    headerNavigation: { ...headerNavStandard },
    footerNavigation: {
      __typename: 'FooterNavigation',
      contactSectionHeading: "Let's Talk",
      copyrightInfo: {
        json: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '© 2023 Human Interest, Inc. ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'hyperlink',
                  data: {
                    uri: 'https://humaninterest.com/disclosures/',
                  },
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Disclosures',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'text',
                  value: '',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '655 Montgomery Street, Suite 1800',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '<br/>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: 'San Francisco, California ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '94111',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value:
                    'Human Interest Inc. is an affordable, full-service 401(k) and 403(b) provider that seeks to make it easy for small and medium-sized businesses to assist their employees with investing for retirement. For more information, please visit ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'hyperlink',
                  data: {
                    uri: 'https://www.humaninterest.com',
                  },
                  content: [
                    {
                      nodeType: 'text',
                      value: 'humaninterest.com',
                      marks: [
                        {
                          type: 'underline',
                        },
                      ],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'text',
                  value:
                    '. Investment Advisory services are provided through Human Interest Advisors LLC, a Registered Investment Adviser and subsidiary of Human Interest Inc. For more information on our investment advisory services, please visit ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'hyperlink',
                  data: {
                    uri: 'https://www.humaninterest.com/hia/',
                  },
                  content: [
                    {
                      nodeType: 'text',
                      value: 'https://www.humaninterest.com/hia/',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'text',
                  value: '.',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value:
                    'This content has been prepared for informational purposes only, and should not be construed as tax, legal, or individualized investment advice. Human Interest Advisors LLC does not provide tax or legal advice. Consult an appropriate professional regarding your situation. The views expressed are subject to change. In the event third-party data and/or statistics are used, they have been obtained from sources believed to be reliable; however, we cannot guarantee their accuracy or completeness. Investing involves risk, including risk of loss. Past performance does not guarantee future results.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
      },
      contactSectionBlocksCollection: {
        items: [
          {
            sys: {
              id: '41prcsESpADhI9gUtTmhaC',
            },
            content: {
              json: {
                nodeType: 'document',
                data: {},
                content: [
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Contact Support',
                        marks: [],
                        data: {},
                      },
                    ],
                  },
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        nodeType: 'text',
                        value: '855 622 7824',
                        marks: [
                          {
                            type: 'bold',
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Monday - Friday',
                        marks: [],
                        data: {},
                      },
                    ],
                  },
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        nodeType: 'text',
                        value: '6am to 5pm PT / 9am to 8pm ET',
                        marks: [],
                        data: {},
                      },
                    ],
                  },
                ],
              },
            },
          },
          {
            sys: {
              id: 'Mz41bPaoBltaIiiG1wmWa',
            },
            content: {
              json: {
                nodeType: 'document',
                data: {},
                content: [
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        nodeType: 'text',
                        value: '',
                        marks: [],
                        data: {},
                      },
                      {
                        nodeType: 'hyperlink',
                        data: {
                          uri: 'https://support.humaninterest.com/s/contactsupport',
                        },
                        content: [
                          {
                            nodeType: 'text',
                            value: 'Contact support online',
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                      {
                        nodeType: 'text',
                        value: '',
                        marks: [],
                        data: {},
                      },
                    ],
                  },
                ],
              },
            },
          },
          {
            sys: {
              id: '6BCYf2qkEbL3VsV0JHB7j3',
            },
            content: {
              json: {
                nodeType: 'document',
                data: {},
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Talk to Sales',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: '',
                        marks: [
                          {
                            type: 'bold',
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: 'hyperlink',
                        content: [
                          {
                            nodeType: 'text',
                            value: '877 475 0179',
                            marks: [
                              {
                                type: 'bold',
                              },
                            ],
                            data: {},
                          },
                        ],
                        data: {
                          uri: 'tel:1-877-475-0179',
                        },
                      },
                      {
                        nodeType: 'text',
                        value: '',
                        marks: [
                          {
                            type: 'bold',
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
              },
            },
          },
        ],
      },
      socialMediaIconsCollection: {
        items: [
          {
            sys: {
              id: '3r2TwGmJjlulWcwwIdns6J',
            },
            title: 'Facebook',
            icon: 'facebook',
            url: 'https://www.facebook.com/humaninterestco/',
          },
          {
            sys: {
              id: '3YdrCJh6XWXD7RFyM21xaJ',
            },
            title: 'LinkedIn',
            icon: 'linkedin',
            url: 'https://www.linkedin.com/company/humaninterest/',
          },
          {
            sys: {
              id: '5Tv5RphWqoUzqJLl6pqf6e',
            },
            title: 'Twitter',
            icon: 'twitter',
            url: 'https://twitter.com/humaninteresthq',
          },
        ],
      },
      sectionsCollection: {
        items: [
          {
            sys: {
              id: '3l6EwcO7wwZecRFKh3HY3f',
            },
            title: 'Company',
            itemsCollection: {
              items: [
                {
                  sys: {
                    id: '4Qx7NfU4A28E6Kp21Poqg2',
                  },
                  label: 'About',
                  url: '/about-us/',
                },
                {
                  sys: {
                    id: '2PGpu6swiPuryS7JxT6ZyX',
                  },
                  label: 'Careers',
                  url: '/careers/',
                },
                {
                  sys: {
                    id: '2G7NyEvIFGvHQPKXdMdnYG',
                  },
                  label: 'Pressroom',
                  url: '/pressroom/',
                },
                {
                  sys: {
                    id: '674WK43gYRzO7y4Zb8n5EV',
                  },
                  label: 'Security',
                  url: 'https://security.humaninterest.com/',
                },
                {
                  sys: {
                    id: '4u5sJ0ZNwn2GdrBc6vPPYi',
                  },
                  label: 'Legal',
                  url: '/legal/',
                },
                {
                  sys: {
                    id: '1UzTu3SuEORPqsSm9oMwFJ',
                  },
                  label: 'HIA',
                  url: '/hia/',
                },
                {
                  sys: {
                    id: '5g3jVNLDQGDd6mqQboHKRf',
                  },
                  label: 'Investment Advice',
                  url: '/hia/investments/',
                },
              ],
            },
          },
          {
            sys: {
              id: 'gpUqb5ZE1aPLMTocmhFza',
            },
            title: 'Solutions',
            itemsCollection: {
              items: [
                {
                  sys: {
                    id: 'rmY4R7AMWjWiQydJEqbkz',
                  },
                  label: '401(k) Plans',
                  url: '/solutions/401k-for-small-business/',
                },
                {
                  sys: {
                    id: '2sab5jQP1HwyjZUBmal5E5',
                  },
                  label: '403(b) Plans',
                  url: '/solutions/403b-retirement-plans/',
                },
                {
                  sys: {
                    id: 'UPJQRFXPGxqjLYhfgtJTd',
                  },
                  label: 'Safe Harbor',
                  url: '/solutions/safe-harbor-401k-plans/',
                },
                {
                  sys: {
                    id: '245RUMDL0RTzBk21xbHxeu',
                  },
                  label: 'Get Started',
                  url: '/signup/',
                },
                {
                  sys: {
                    id: '35VUHwzPYsVHgyEwNEm0Nw',
                  },
                  label: 'Plan Cost Calculator',
                  url: '/learn/calculators/plan-cost-calculator/',
                },
                {
                  sys: {
                    id: '1lOVfBB6LXvvvChIz1TB8d',
                  },
                  label: '(k)ickstart™',
                  url: '/solutions/kickstart/',
                },
              ],
            },
          },
          {
            sys: {
              id: '2V2E4Mt460oSYanPqOgEYI',
            },
            title: 'Learn',
            itemsCollection: {
              items: [
                {
                  sys: {
                    id: '4jwCVkfAroLL4oS3GJW79u',
                  },
                  label: 'For Employers',
                  url: '/learn/employers/',
                },
                {
                  sys: {
                    id: 'ReYlaXPUqVsk6sT0CGL2d',
                  },
                  label: 'For Employees',
                  url: '/learn/employees/',
                },
                {
                  sys: {
                    id: '43802oMRv29BEr5g6XERVA',
                  },
                  label: 'Legislation',
                  url: '/learn/legislation/',
                },
                {
                  sys: {
                    id: 'qdtIUbyeMwghcgPbXhs4d',
                  },
                  label: 'Webinars',
                  url: '/solutions/webinars/',
                },
                {
                  sys: {
                    id: '4pCgOJ96cNyVwfILs6XMMU',
                  },
                  label: 'FAQ',
                  url: '/faq/',
                },
              ],
            },
          },
          {
            sys: {
              id: '5vrE6rq0QhbokO9UTa7DgS',
            },
            title: 'Industries',
            itemsCollection: {
              items: [
                {
                  sys: {
                    id: '4ECiq8u3gq740gxvY6y5A3',
                  },
                  label: 'Startups',
                  url: '/industries/retirement-plans-for-startups/',
                },
                {
                  sys: {
                    id: '47wdtfIitPAcGM7WBWrKDK',
                  },
                  label: 'Franchises',
                  url: '/industries/retirement-plans-for-franchises/',
                },
                {
                  sys: {
                    id: '553aTZewY1eO7IlXXDn8Gn',
                  },
                  label: 'Nonprofits',
                  url: '/industries/retirement-plans-for-nonprofits/',
                },
                {
                  sys: {
                    id: '2sD2bvY4SFxyWVMHsLUGpS',
                  },
                  label: 'Restaurants',
                  url: '/industries/retirement-plans-for-restaurants/',
                },
                {
                  sys: {
                    id: '4qa8O1i5LuuE7iM6aLRHMI',
                  },
                  label: 'Law Firms',
                  url: '/industries/retirement-plans-for-law-firms/',
                },
                {
                  sys: {
                    id: '4d6iY2KZAnlh7BdYOmsghV',
                  },
                  label: 'Dentists',
                  url: '/industries/retirement-plans-for-dentists/',
                },
                {
                  sys: {
                    id: '2xako0Dww7vgLXdIuodHog',
                  },
                  label: 'Doctors',
                  url: '/industries/retirement-plans-for-doctors/',
                },
              ],
            },
          },
        ],
      },
    },
  },
  layoutType: 'primary',
  notes: [
    {
      sys: {
        id: '3pgJzzRQmbJBVo0wYpoK6b',
      },
      content: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Rating based on ',
                  nodeType: 'text',
                },
                {
                  data: {
                    uri: 'https://example.com',
                  },
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: '2022 Fake Ratings Survey',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'hyperlink',
                },
                {
                  data: {},
                  marks: [],
                  value: '.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
      },
    },
  ],
  disclosures: [
    {
      sys: {
        id: '6G9ftGpJLeWrYgxFiCHM2G',
      },
      content: {
        json: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '2 ',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value:
                    'Safe harbor notices must be delivered within a reasonable period before the effective date. Notice is automatically considered timely if given at least 30 days in advance.',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '<br>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '3',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value:
                    ' Certain plans may have until September 23, 2022, to sign order forms. Signed plan documents, initial payroll integration, and bank deposits must be verified by October 1, 2022, to ensure at least three months of deferral opportunity are offered to plan participants. Contact Human Interest to learn more.',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '<br>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '4 ',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: 'Human Interest, July 2022.',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '<br>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '5 Nonelective contribution deadlines:',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value:
                    ' New and existing plans follow the same deadlines to establish or add a safe harbor nonelective contribution. ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: 'December 1, 2022',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ', is the last day to add a 3% NEC for 2022, while ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: 'December 31, 2023',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value:
                    ', is the last day to add a 4% NEC for 2022. A plan may only be a safe harbor plan for the first plan year if deferrals can be made to the plan for at least ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '3 months',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value:
                    ' in the first plan year. If a plan is set up by October 1 (for calendar year plans), the sponsor can still amend to add a safe harbor nonelective contribution at a later date.',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '<br>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '6',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value:
                    " A Human Interest Advisory Fee of 0.50% of plan assets per year is billed to the employee's account according to the ",
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'hyperlink',
                  data: {
                    uri: 'https://humaninterest.com/terms-of-service/',
                  },
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Terms of Service',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'text',
                  value:
                    '. In the event that the plan sponsor wants to bring in a third-party advisor, the third-party advisor fee would be defined as part of a separate agreement.',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '<br>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '7 ',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: 'According to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'hyperlink',
                  data: {
                    uri: 'https://www.pewtrusts.org/-/media/assets/2017/02/ret_retirement_plan_access_and_participation_across_generations.pdf',
                  },
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Pew Charitable Trusts',
                      marks: [
                        {
                          type: 'underline',
                        },
                      ],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'text',
                  value:
                    ' research from 2017, when a match is offered, takeup rises by 15 to 15.5 percentage points in each generation. ',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '<br>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '8 ',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: 'Retirement Plans Startup Costs Tax Credit',
                  marks: [
                    {
                      type: 'italic',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value:
                    '. IRS.gov. https://www.irs.gov/retirement-plans/retirement-plans-startup-costs-tax-credit',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '<br>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '9 ',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: 'Refer to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'hyperlink',
                  data: {
                    uri: 'https://humaninterest.com/payrolls',
                  },
                  content: [
                    {
                      nodeType: 'text',
                      value: 'https://humaninterest.com/payrolls',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'text',
                  value: '/ for a list of integrated payroll providers.',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '<br>',
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: '',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
      },
    },
  ],
};
