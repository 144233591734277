import { Head } from 'humanity/components/head';
import { pageContainerProps } from 'humanity/pages/pageContainer/pageContainer.types.js';
import Layout from 'layouts';

const PageContainer = ({
  layout,
  seoMetadata,
  children,
  layoutType = 'primary',
  notes = [],
  disclosures = [],
}) => (
  <Layout type={layoutType} layout={layout} notes={notes} disclosures={disclosures}>
    <Head
      title={seoMetadata?.title}
      description={seoMetadata?.description}
      url={`${process.env.NEXT_PUBLIC_HI_BASE_URL}${seoMetadata?.canonicalUrl}`}
      image={seoMetadata?.image?.url}
      noIndex={seoMetadata?.noIndex}
    />
    {children}
  </Layout>
);

PageContainer.propTypes = {
  ...pageContainerProps,
};

export default PageContainer;
